import React from "react"
import { graphql, PageProps } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"
import { CalculatorSelectContext } from "@containers/CalculatorSelect"
import CalculatorSelectCountryContainer from "@containers/CalculatorSelect/CalculatorSelectCountryContainer"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import Hero from "@components/Calculator/Hero"
import HowTo from "@components/Calculator/HowTo"
import Calculator from "@components/Calculator/Calculator"
import InfoBlock from "@components/Calculator/InfoBlock"
import CtaSection from "@components/Calculator/CtaSection"
import BottomHero from "@components/Calculator/BottomHero"
import { CalculatorPageDataType } from "@typings/requests/partners/calculator"

const CalculatorPage: React.FC<PageProps<CalculatorPageDataType>> = props => {
  const {
    allStrapiCalculatorPage: {
      nodes: [calculatorData],
    },
    allStrapiPageFooter: {
      nodes: [footerData],
    },
  } = props.data
  const {
    hero,
    howTo,
    calculatorSection,
    topInfo,
    cta,
    bottomInfo,
    bottomHero,
  } = calculatorData
  return (
    <>
      <SEO
        title={calculatorData.title}
        description={calculatorData.description}
        pathname={props.location.pathname}
        image={calculatorData.metaImage.localFile.url}
        lang="en"
      />
      <ParallaxProvider>
        <LayoutContainer
          className="calculator-page"
          location={props.location}
          lang="en"
          isPartnerSubdomain
        >
          <Hero
            {...hero}
            image={hero.image.localFile.childImageSharp.gatsbyImageData}
            secondImage={
              hero.secondImage.localFile.childImageSharp.gatsbyImageData
            }
          />
          <HowTo {...howTo} />
          <CalculatorSelectContext {...calculatorSection}>
            <Calculator
              calculatorSelectCountryComponent={
                <CalculatorSelectCountryContainer />
              }
            />
          </CalculatorSelectContext>
          <InfoBlock {...topInfo} position={"top"} />
          <CtaSection
            {...cta}
            image={cta.image.localFile.childImageSharp.gatsbyImageData}
          />
          <InfoBlock {...bottomInfo} position={"bottom"} />
          <BottomHero
            {...bottomHero}
            image={bottomHero.image.localFile.childImageSharp.gatsbyImageData}
            footerData={footerData}
          />
        </LayoutContainer>
      </ParallaxProvider>
    </>
  )
}

export const query = graphql`
  {
    allStrapiCalculatorPage(filter: { locale: { eq: "en" } }) {
      nodes {
        id
        title
        description
        metaImage {
          localFile {
            publicURL
            relativePath
            url
            uid
          }
        }
        bottomInfo {
          calculatorInfoItem {
            description
            id
            title
          }
          id
          title
        }
        calculatorSection {
          button {
            id
            label
            url
          }
          costHeader
          ctaTitle
          id
          inputLabel
          title
          footnote
        }
        cta {
          buttonLink: button {
            id
            label
            url
          }
          id
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          title
        }
        hero {
          description
          id
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: NONE
                  layout: CONSTRAINED
                  width: 378
                )
              }
            }
          }
          secondImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: CONSTRAINED
                  width: 378
                )
              }
            }
          }
          title
        }
        howTo {
          description
          firstLine
          id
          secondLine
          title
        }
        topInfo {
          calculatorInfoItem {
            description
            id
            title
          }
          id
          title
        }
        bottomHero {
          buttonLink: button {
            label
            url
          }
          description
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    allStrapiPageFooter(filter: { locale: { eq: "en" } }) {
      nodes {
        title
        socialLink {
          name
          title
          url
        }
      }
    }
  }
`
export default CalculatorPage
